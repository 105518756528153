import React, { useCallback, useEffect, useState, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import logo from './images/parkit-logo-horizontal.png';

const SOCKET_SERVER_URL = 'wss://parkit-english-expert-api.parkit-staging.com/stream';

function App() {
  const [input, setInput] = useState('');
  const [messageHistory, setMessageHistory] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [tone, setTone] = useState('None');
  const timer = useRef<ReturnType<typeof setInterval>>()
  const { sendMessage, lastMessage } = useWebSocket(SOCKET_SERVER_URL, {
    shouldReconnect: (closeEvent) => true,
  });

  const debounceLoading = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      // For when no response clean up
      setMessageHistory(prev => {
          if (prev[prev.length - 1] === "") {
              return prev.slice(0, -1);
          }
          return prev;
      });
      setLoading(false);
    }, 5000);
  }, [setMessageHistory]);

  const onSubmit = useCallback(() => {
    setLoading(true);
    setMessageHistory(prev => [...prev, ""]);
    sendMessage(JSON.stringify(
      {
        text: input,
        tone: tone
      }));
    setInput('');
    debounceLoading();
  }, [input, sendMessage, tone, debounceLoading]);

  useEffect(() => {
    if (lastMessage !== null) {
      debounceLoading();
      setMessageHistory((prev) => [...prev.slice(0, -1), `${prev[prev.length - 1]}${lastMessage.data}`]);
    }  
  }, [lastMessage, setMessageHistory, debounceLoading]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <div className="bg-[#ffffff] flex flex-col items-center pt-10 min-h-screen">
      <img className="mb-4 h-16" src={logo} alt="logo" />
      <div className="w-10/12 lg:w-1/2 mb-4">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="bg-[#ffffff] text-[#363636] border-2 border-[#c2c2c2] p-2 w-full h-48 rounded"
          placeholder="Type something..."
        />
      </div>
      <div className="w-10/12 lg:w-1/2 mb-4 flex justify-between items-center mt-4">
        <button
          disabled={loading || input.trim() === ''}
          onClick={onSubmit}
          className="bg-primary900 disabled:opacity-50 text-white px-10 py-2 rounded mr-2 flex-grow"
        >
          Submit
        </button>
        <select
          value={tone}
          onChange={(e) => setTone(e.target.value)}
          className="bg-[#ffffff] border-2 border-[#c2c2c2] p-2 rounded ml-2 flex-shrink"
        >
          <option value="Default Tone">None</option>
          <option value="Persuasive">Persuasive</option>
          <option value="Friendly">Friendly</option>
          <option value="Formal">Formal</option>
          <option value="Informal">Informal</option>
          <option value="Professional">Professional</option>
          <option value="Neutral">Neutral</option>
          <option value="Critical">Critical</option>
        </select>
      </div>
      <div className="flex mb-2 w-10/12 lg:w-1/2 h-80">
        <div className="w-full bg-[#ffe8e8] text-[#363636] p-3 overflow-y-auto">
          <div className="flex flex-col gap-5">
            {messageHistory.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
